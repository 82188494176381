import React from 'react';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import Terms from '../components/pages/terms';

const seo = {
  title: 'Terms of Use',
  description: 'Terms of Use for www.livemenu.io.',
  keywords: ['terms', 'use', 'policy'],
};

const TeamPage = props => {
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Terms />
    </Layout>
  );
};

export default TeamPage;
